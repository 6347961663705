.mobile_title {
  display: none;
}

.error_sidebar {
  background-color: white;
  flex: 1;
}

.error_wrapper {
  flex: 4;
  overflow: hidden;
}

.error_content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.error_circle{
    border-radius: 50%;
    height: 25rem;
    width: 25rem;
    margin-bottom: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
    background: linear-gradient(to bottom, #FDE047, #FFFFFF);
}
@media screen and (max-width: 768px) {
  .mobile_title {
    display: block;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }

  .error_sidebar {
    background-color: white;
    flex: none;
  }

  .error_wrapper {
    flex: 1;
  }

  .error_circle{
    height: 20rem;
    width: 20rem;
}

.error_content{
    justify-content: flex-start;

}
}

