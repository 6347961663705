@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  overflow-x: hidden;
}
/* To get the blinking effect of Text in pages/dashboard/analysis/VideoRecord */
@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-blinking {
  animation: blinking 1s infinite; /* Use 1s for 1 second blinking effect */
}

/* Loader CSS used in pages/dashboard/analysis/Analysis */
.custom-loader {
  width: 100px;
  height: 100px;
  display: grid;
}
.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  --c: radial-gradient(farthest-side, #fcd34d 92%, #fde68a);
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  animation: s2 1s infinite;
}
.custom-loader::before {
  margin: 8px;
  filter: hue-rotate(45deg);
  background-size: 16px 16px;
  animation-timing-function: linear;
}

@keyframes s2 {
  100% {
    transform: rotate(0.5turn);
  }
}

/* These styled used in app.js file */

.app_wrapper {
  display: flex;
}

.app_sidebar {
  flex: 1;
  background-color: white;
  transition: all 0.8s ease;
}

.collapsed_sidebar {
  flex: 0;
  min-width: 4rem;
  background-color: rgb(254 240 138);
}
.app_body {
  background-color: #f3f4f6;
  flex: 4;
}

@media screen and (max-width: 768px) {
  .app_wrapper {
    display: block;
  }
}
