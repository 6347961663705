.mobile_title{
    display: none;
}

.category_links_wrapper{
    min-height: 85vh; 
}

/* Action button, View history */
/* .dialog_nav{
    width: 55rem;
} */

.dialog_title{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.diolog_boxes{
    display: flex;
    padding: 1rem;
    gap: 0.5rem;
}
@media screen and (max-width: 768px) {
    .mobile_title {
      display: block;
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
    }

    .dialog_title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1rem;
    }

    .diolog_boxes{
        display: flex;
        padding: 1rem;
        gap: 1rem;
        flex-direction: column;
    }
}