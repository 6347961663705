.mobile_title {
  display: none;
}

.category_links_wrapper {
  min-height: 85vh;
}

/* Templated Card */

.search_wrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}

.search_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 2rem;
  transition: border-color 0.3s ease-in-out;
  background-color: white;
}

.search_bar:hover {
  border-color: #555;
}

.clear_search {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s ease-in-out;
}

.clear_search:hover {
  color: rgba(255, 0, 0, 0.726);
}

.search_bar input {
  border: none;
  outline: none;
  background: transparent;
  padding: 4px 8px;
  animation: inputFocusAnimation 0.3s ease-in-out;
  font-size: 16px;
  font-weight: 500;
}
/* Animation for input field */
@keyframes inputFocusAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .mobile_title {
    display: block;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }
}
