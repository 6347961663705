/* Announcement styles */

.announcement {
  background-color: hsl(54, 100%, 74%);
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.announcement h3 {
  font-weight: 500;
  color: black;
  opacity: 0.8;
  line-height: 1.4;
}

.read_more {
  font-weight: 500;
  color: black;
  opacity: 0.8;
  transition: all 1s ease;
  border-bottom: 2px solid rgba(0, 0, 0, 0.897);
}

.read_more:hover {
  font-weight: 600;
  transform: scale(1.1);
}

/* Header styles */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  background-color: white;
}

.header_inner {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header_inner img {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.header_inner span {
  font-weight: 700;
  font-size: 1.5rem;
}

.li {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.links {
  color: black;
  opacity: 0.8;
  font-weight: 500;
}

.button1 {
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: green;
  opacity: 0.7;
  border-radius: 999px;
  background-color: hsl(54, 100%, 74%);
  transition: background-color 0.1s;
  cursor: pointer;
}
.button1:hover {
  background-color: hsl(54, 100%, 64%);
}

/* Dashboar img in Landing Page */
.dashboard_ss_div{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  /* border: 2px solid red; */

}

/* .dashboard_ss_div img{
  width: 100%;
  height: auto;
} */



/* Footer styles */

.footer {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 0.5rem;
}

.footer_inner {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.logo span {
  font-weight: 700;
  font-size: 1.125rem;
}

.links {
  display: flex;
  gap: 2rem;
}

/* 768px or below screenas */
@media screen and (max-width: 768px) {
  .announcement{
    display: none;
  }
  /* Header styles */
  .header {
    padding: 1rem 2rem;
  }

  .header_inner img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .header_inner span {
    font-weight: 700;
    font-size: 1.25rem;
  }

  .li .links {
    display: none;
  }

  /* Footer styles */
  .footer {
    gap: 2rem;
    padding: 1rem;
    justify-content: center;
  }

  .footer_inner {
    gap: 1rem;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .logo img {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  .logo span {
    display: none;
    font-weight: 600;
    font-size: 1rem;
  }

  .date {
    display: none;
  }

  .links {
    display: flex;
    gap: 1rem;
  }
}
