/* Show the title in Mobile screens */
.mobile_title {
  display: none;
}

/* Dashboard */
.dashboard {
  display: flex;
}

.main_dashboard_wrapper {
  flex: 1;
}

.dashboard_rightbar {
  margin-right: 1rem;
  width: 18rem;
}

/* box1 in mainDashboard */
.box1 {
  width: 45%;
  height: auto;
  border-radius: 1rem;
  padding: 1.5rem;
  background-color: #fff27a;
}

.indicator {
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 32%;
  margin: 0 auto;
  user-select: none;
}

/* box2 in mainDashboard */
.box2 {
  background-image: url("../../resources/img2.png");
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 45%;
  height: auto;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
}

/* box3 and box4 in mainDashboard */
.box3 {
  background-color: #333334;
  width: 45%;
  height: auto;
  border-radius: 1rem;
  padding: 1.5rem;
}
.box3_desc {
  margin-top: -5rem;
}

/* Payment in mainDashboard on click of Upgrad btn */
.payment_wrapper {
  display: flex;
  gap: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.payment_card {
  flex: 1;
}

.payment_right_bar {
  margin-right: 13rem;
  width: 18rem;
}

/* Analysis Page on click of Take Analysis btn */
.analysis_wrapper {
  display: flex;
  gap: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.analysis_card {
  flex: 1;
}

.analysis_result {
  width: 18rem;
  /* height: 20rem; */
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 8rem;
  margin-right: 1rem;
}

/* VideoRecord inside analysis Page on click of Take Analysis btn */
.camera_dimentions{
  min-width: 40rem;
  min-height: 24rem;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid rgba(128, 128, 128, 0.658);
  position: relative;
  transition: all 0.4s ease;
}

.video_actions_div{
  position: absolute;
  transition: all 0.4s ease;
  padding: 0.5rem 1rem;
  bottom: 2rem;
  background-color: transparent;
  color: black;
  width: 10rem;
  display: flex;
  justify-content: center;
  /* z-index: 2; */
}

.icon_color {
  color: #e91e63 !important ; 
  transition: color 0.4s ease;
}

.disabled_icon_color{
  color: gray !important ; 

}


/* .video_actions_div:hover{
  background-color: #FEF08A;
  color: black;
} */


@media screen and (max-width: 768px) {
  .mobile_title {
    display: block;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }
  .dashboard {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .main_dashboard_wrapper {
    flex: auto;
  }

  .dashboard_rightbar {
    margin-right: 1rem;
    width: 100%;
  }

  .main_dashboard {
    flex-direction: column !important;
  }

  .box1 {
    width: 100%;
  }

  .box2 {
    width: 100%;
    height: 20rem;
  }

  .box3 {
    width: 100%;
  }

  .payment_wrapper {
    display: block;
    margin-left: 0rem;
    margin-bottom: 0rem;
  }

  .payment_right_bar {
    margin-top: 1rem;
    margin-right: 0rem;
    width: 100%;
  }

  .analysis_wrapper {
    display: block;
    margin-left: 0rem;
    margin-bottom: 0rem;
  }

  .analysis_result {
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    margin-right: 0rem;
    justify-content: center;
  }

  .camera_dimentions{
    min-width: 16rem;
    min-height: 12rem;
    background-color: white;
    overflow: hidden;
    border-radius: 1rem;
  }

  .video_actions_div{
    bottom: 1rem;
  }
}
