.mobile_title{
    display: none;
}

.category_links_wrapper{
    min-height: 85vh; 
    padding: 1rem;
}

@media screen and (max-width: 768px) {
    .mobile_title {
      display: block;
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
    }
}