.text {
  padding:2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  gap: 1.2rem;
  background-color: #333334;
}

.heading1 {
  font-weight: 700;
  font-size: 2rem;
  color: white;
  line-height: 1.2;
  text-transform: capitalize;
}

.subheading1 {
  font-weight: 500;
  font-size: 1.1rem;
  color: white;
  line-height: 1.2;
}

.form {
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 999px;
}

.input {
  padding: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 1rem;
}

.input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  /* outline: none; */
}

.button1 {
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 999px;
  background-color: hsl(54, 100%, 74%);
  transition: background-color 0.1s;
  cursor: pointer;
}
.button1:hover {
  background-color: hsl(54, 100%, 64%);
}

.light_text{
  font-weight: 300;
  color: white;
  opacity: 0.5;
}

/* 768px or below screenas */
@media screen and (max-width: 768px) {
  .text {
    padding: 1rem;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .heading1 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .subheading1 br {
    display: none;
  }

  .subheading1 {
    font-weight: 400;
    font-size: 1rem;
  }

  .form {
    background-color: transparent;
    padding: 0rem 0rem;
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input {
    width: 20rem;
    padding: 0.5rem;
    font-weight: 400;
    transition: all 0.5s ease-in-out;
  }
  .input:focus {
    background-color: hsl(0, 0%, 10%);
    color: white;
  }

  .light_text{
    opacity: 0.8;
  }
}
