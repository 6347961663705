.section1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333334;
  padding: 2rem;
}

.section1 .text {
  flex: 1;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: start;
  gap: 1.2rem;
  background-color: #333334;
}

.heading1 {
  font-weight: 700;
  font-size: 2rem;
  color: white;
  line-height: 1.2;
  text-transform: capitalize;
}

.subheading1 {
  font-weight: 500;
  font-size: 1.1rem;
  color: white;
  line-height: 1.2;
}

.form {
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 999px;
}

.input {
  padding: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 1rem;
}

.input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  /* outline: none; */
}

.button1 {
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 999px;
  background-color: hsl(54, 100%, 74%);
  transition: background-color 0.1s;
  cursor: pointer;
}
.button1:hover {
  background-color: hsl(54, 100%, 64%);
}

.dashboard_img_div {
  flex: 1;
  background-color: transparent;
  padding-right: 1rem;
  padding-top: 1rem;
  overflow: hidden;
  position: relative;
}

.dashboard_img_div img {
  width: 100%;
  height: auto;
  animation: flyingEffect 4s ease-in-out infinite;
}
@keyframes flyingEffect {
  0%,
  100% {
    transform: translateY(0); /* Start and end position of the animation */
  }
  50% {
    transform: translateY(-20px); /* Midpoint position, adjust as needed */
  }
}

.brands_wrapper {
  padding: 1rem;
}

.brands {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
}

.brands img {
  height: 6rem;
  width: 6rem;
}

.brands_wrapper p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2;
  padding: 0.75rem 0;
}

/* 768px or below screenas */
@media screen and (max-width: 768px) {
  .section1 {
    padding: 1rem;
    flex-direction: column;
  }

  .section1 .text {
    padding: 0 0rem;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .heading1 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .heading1 br {
    display: none;
  }

  .subheading1 {
    font-weight: 400;
    font-size: 1rem;
  }

  .form {
    background-color: transparent;
    padding: 0rem 0rem;
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input {
    width: 20rem;
    padding: 0.5rem;
    font-weight: 400;
    transition: all 0.5s ease-in-out;
  }
  .input:focus {
    background-color: hsl(0, 0%, 10%);
    color: white;
  }

  .dashboard_img_div {
    margin-top: 1.5rem;
    padding-right: 0rem;
  }

  .brands_wrapper {
    margin-top: 1rem;
    padding: 1rem;
  }

  .brands {
    padding: 0 0.5rem;
    gap: 2rem;
  }

  .brands img {
    margin: 0 1rem;
  }

  .brands_wrapper p {
    font-weight: 500;
    text-align: start;
  }
}
