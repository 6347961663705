.take_analysis {
  width: 100vw;
  height: 100vh;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

/* pseudo-element to create the clip-path shape on background */
.take_analysis::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    81.5% 84.75%,
    40.2% 84.75%,
    16.44% 84.75%,
    0% 73.75%
  );
  /* background-image: linear-gradient(
    75deg,
    hsl(20deg 99% 68%) 0%,
    hsl(19deg 95% 66%) 28%,
    hsl(17deg 91% 63%) 47%,
    hsl(15deg 88% 61%) 61%,
    hsl(14deg 85% 58%) 71%,
    hsl(12deg 82% 56%) 79%,
    hsl(11deg 80% 54%) 85%,
    hsl(9deg 78% 51%) 89%,
    hsl(8deg 78% 49%) 93%,
    hsl(6deg 83% 47%) 96%,
    hsl(4deg 88% 45%) 98%,
    hsl(3deg 94% 43%) 100%
  ); */
  background-image: linear-gradient(
    75deg,
    hsl(39, 68%, 61%) 0%,
    hsl(39, 72%, 72%) 25%,
    hsl(39, 78%, 76%) 50%,
    hsl(39, 90%, 80%) 100%
  );

  z-index: 1;
}

.job_title {
  z-index: 4;
  margin-top: 1.5rem;
  width: 100%;
  height: max-content;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
}

.back_icon_wrapper {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  transition: all 0.4s ease;
}

.back_icon {
  font-size: 3rem !important;
}

.icon_wrapper:hover .icon {
  color: red !important;
}

.icon {
  color: white;
}

.video {
  max-width: 130% !important;
}

.video_card,
.question_card {
  height: 80vh;
  position: absolute;
  background-color: white;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.video_card {
  width: 62vw;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  /* position: relative; */
}

.video_actions_div {
  position: absolute;
  transition: all 0.4s ease;
  padding: 0.5rem 1rem;
  bottom: 1rem;
  background-color: transparent;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  z-index: 2;
}

.icon_wrapper {
  border: 2px solid white !important;
  transition: all 0.4s ease;
}

.disabled_icon_wrapper {
  border: 2px solid gray !important;
  transition: all 0.4s ease;
}

.icon_wrapper:hover {
  background-color: white !important;
}

.icon_wrapper:hover .icon {
  color: red !important;
}

.disabled_icon {
  color: grey;
}

.active_record {
  background-color: white !important;
}

.active_icon {
  color: red !important;
}

.question_card {
  width: 26vw;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
}

.timer {
  display: flex;
  justify-content: space-between;
  width: 23.5vw;
  margin-bottom: 0.5rem;
}

.progress_container {
  width: 100%;
  height: 16px;
  /* padding: 0.4rem; */
  background-color: #ccc;
  border: 4px solid #ccc;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.progress_bar {
  height: 100%;
  border-radius: 1rem;
  background-color: rgb(76, 175, 56); /* Progress bar color */
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 23.5vw;
  margin-top: 0.5rem;
}

.buttons .button {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: rgb(76, 175, 56);
  transition: all 0.4s ease;
  cursor: pointer;
}
.buttons .button:hover {
  transform: scale(1.15);
  background-color: rgba(76, 175, 56, 0.712);
}

.disabled_button {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: rgba(76, 175, 56, 0.377);
  transition: all 0.4s ease;
  cursor: not-allowed;
}

/* Result */
.result_wrapper {
  width: 50vw;
  height: 50vh;
}

.scores {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .take_analysis {
    height: max-content;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
  }
  .take_analysis::before {
    display: none;
  }
  .mobile_screen {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    background-color: transparent;
  }

  .video {
    max-width: 110% !important;
  }

  .job_title {
    margin-top: 0.5rem;
  }
  .video_card,
  .question_card {
    height: max-content;
    position: relative;
  }

  .video_card {
    width: 80vw;
    position: relative;
    left: 0%;
    top: 0%;
    transform: translateY(0%);
    margin: 0 auto;
  }

  .question_card {
    width: 100%;
    padding: 1rem;
    position: relative;
    right: 0%;
    top: 0%;
    transform: translateY(0%);
  }

  .question_card h4 {
    text-align: center;
  }

  .question_wrapper {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .timer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .progress_bar {
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    background-color: rgb(76, 175, 56); /* Progress bar color */
  }

  .buttons {
    width: auto;
  }
  .result_wrapper {
    width: 80vw;
    height: auto;
  }

  .scores {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}
