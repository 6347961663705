
.section2 {
  text-align: center;
  gap: 1.2rem;
  background-color: white;
  padding: 2rem 1rem;
}

.inner {
  margin-bottom: 2rem;
}

.heading1 {
  font-weight: 700;
  font-size: 1.75rem;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  line-height: 1.2;
  margin-bottom: 1rem;
}

.button1 {
  line-height: 1.2;
  font-size: 1.5rem;
  font-weight: 600;
}

.inner2 {
  margin-bottom: 3rem;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: 1rem;
}
.video_audio{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}


.links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-bottom: 1rem;
}
.links span{
  cursor: pointer;
}



.active {
  color: black;
  opacity: 1;
  border-bottom: 2px solid black;
  font-weight: 500;
}

.not_active {
  color: black;
  opacity: 0.7;
  font-weight: 400;
}

.fade-in-out {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-in-out.hidden {
  opacity: 0;
}

.video_text_wrapper {
  background-color: #F9F9F9 ;
  width: 24rem;
  text-align: left;
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.video_text_wrapper h5 {
  font-weight: 600;
  font-size: 1.125rem;
  color: black;
  opacity: 0.9;
}

.video_text_wrapper p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.4;
  color: black;
  opacity: 0.6;
}

.img1 {
  width: 40%;
  height: auto;
}

.img2 {
  width: 40%;
  height: auto;
}

.template {
  margin-bottom: 2rem;
}

.template_inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

/* 768px or below screenas */
@media screen and (max-width: 768px) {
  .section2 {
    text-align: center;
    gap: 1.1rem;
  }

  .inner {
    margin-bottom: 1rem;
  }

  .heading1 {
    font-weight: 500;
    font-size: 1.35rem;
  }

  .button1 {
    padding: 0.5rem 1rem;
    line-height: 1.2;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .inner2 {
    margin-bottom: 1rem;
  }

  .video {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 0;
  }

  .links{
    gap: 2rem;
}
  .active {
    text-align: left;
    margin: 1rem 0;
  }


  /* .not_active {
    display: none;
  } */

  .img1 {
    height: auto;
    width: 100%;
  }
  
  .img2 {
    height: auto;
    width: 100%;
  }

  .template {
    margin-bottom: 0rem;
  }
}
