.mobile_title {
  display: none;
}

.contact_text_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  background-color: white;
  padding: 0.5rem 0;
  height: 16rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact_img img {
  animation: flyingEffect 4s ease-in-out infinite;
}
@keyframes flyingEffect {
  0%,
  100% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(-12px);
  }
}

.form_wrapper{
    display: flex;
    margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .mobile_title {
    display: block;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }

  .contact_img img {
    display: none;
    animation: flyingEffect 4s ease-in-out infinite;
  }

  .contact_text_wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: transparent;
    height: auto;
    box-shadow: none;
    padding: 0rem 0;
    gap: 0rem;
  }

  .form_wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
}
}
