.mobile_icons {
  display: none;
}

.sidebar{
  transition: all 0.8s ease;
}
.toggle_sidebar{
  width: 4rem !important;
  background-color: rgb(254 240 138) ;
  transition: all 0.8s ease;

}

.toggle_sidebar_icon{
  /* border-radius: 999px;
  color: gray;
  background-color: #FEF08A;
  padding: 6px; */
  position: absolute;
  top: 1.25rem;
  right: -3rem;
  transition: all 0.4s ease;
}

.toggle_sidebar_icon:hover{
  color: rgb(63, 62, 62);
  transform: scale(1.15);
}


.collapsed_links {
  padding: 1rem 1rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
  /* margin-top: 2rem !important; */
}
.collapsed_links:hover {
  background-color: transparent !important;
}

.collapsed_sidebar{
  margin-left: 0 !important;
  padding: 0 !important;
  padding: 1rem 0 !important;
}

.collapsed_nav{
  margin-left: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 768px) {
  .mobile_hide {
    display: none !important;
  }

  .mobile_sidebar {
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }

  .mobile_icons {
    display: block;
  }

  .icon_div {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
  }

  .close_logo {
    animation: fromRight 0.7s ease;
  }

  .menu_logo {
    animation: fromLeft 0.7s ease;
  }

  .icon {
    cursor: pointer;
    font-size: 44px !important;
    /* color: #fff27a; */
    color: black;
    font-weight: 800;
    padding: 4px;
    transition: transform 0.5s ease, opacity 0.2s ease;
    /* transition: all 3s cubic-bezier(0.25, 1, 0.5, 1); */
  }
  .icon:hover {
    /* transform: scale(1.1) rotate(30deg);  */
    animation: iconAnimation 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards;
  }

  @keyframes iconAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.15) rotate(45deg);
      opacity: 0.8;
    }
    100% {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
  }

  .mobile_links {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-top: 1px solid #ccc;
    animation: fromLeft 0.7s ease;
  }
  @keyframes fromLeft {
    from {
      opacity: 0;
      transform: translateX(-10rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fromRight {
    from {
      opacity: 0;
      transform: translateX(10rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1rem;
  }

  .support_link {
    display: flex;
    gap: 0.5rem;
    font-weight: 600;
    align-items: center;
    margin-bottom: 1rem;
  }
}
